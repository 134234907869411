<template>
    <div>
        <UploadFile :uploadImgServerUrl="uploadImgServerUrl" :uploadVideoServerUrl="uploadVideoServerUrl"
            :showUploadImg="false" :showUploadVideo="false" :uploadImgList="uploadImgList"
            :uploadVideoList="uploadVideoList" @getUploadImgList="getUploadImgList"
            @getUploadVideoList="getUploadVideoList" />
        <div class="container">
            <div class="card" v-for="(item, index) in imgListAndVideoList" :key="index">
                <div class="media">
                    <div class="media-item">
                        <img class="media-item-value" v-if="item.type == 'img'" :src="item.url">
                        <VideoComponent class="media-item-value" v-if="item.type == 'video'" :id="`video${index}`"
                            :video="item" />
                        <div class="media-item-cover" v-show="cover == item.serverFileName">当前封面</div>
                    </div>
                    <div class="media-delete" @click="onDelete(index)">x</div>
                </div>
                <div class="move-btn">
                    <div class="move-btn-value" @click="moveItem(index, 0)" style="flex: 1;">
                        <span v-show="index != 0">往前</span>
                    </div>
                    <div class="move-btn-value" @click="setCover(index)" style="flex: 2;">
                        <span v-show="cover != imgListAndVideoList[index].serverFileName && item.type != 'video'">封面</span>
                    </div>
                    <div class="move-btn-value" @click="moveItem(index, 1)" style="flex: 1;">
                        <span v-show="imgListAndVideoList.length - 1 != index">往后</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import UploadFile from "./UploadFile";
import VideoComponent from "./VideoComponent.vue";
export default {
    props: {
        uploadImgServerUrl: {
            type: String,
            default: ""
        },
        uploadVideoServerUrl: {
            type: String,
            default: ""
        },
        cover: {
            type: String,
            default: ""
        },
        imgListAndVideoList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    components: {
        UploadFile,
        VideoComponent
    },
    data() {
        return {
            uploadImgList: [],
            uploadVideoList: []
        }
    },
    methods: {
        setCover(index) {
            let cover = this.imgListAndVideoList[index].serverFileName
            this.$emit("getCover", cover)
        },
        getUploadImgList(e) {
            this.uploadImgList = e
            if (this.cover == "") {
                this.$emit("getCover", this.uploadImgList[0].serverFileName)
            }
            this.getImgListAndVideoList()
        },
        getUploadVideoList(e) {
            this.uploadVideoList = e
            this.getImgListAndVideoList()
        },
        getImgListAndVideoList() {
            let stays = []
            let imgListAndVideoList = this.imgListAndVideoList
            imgListAndVideoList.forEach(item => {
                if (!item.file) {
                    stays.push(item)
                }
            });
            imgListAndVideoList = []
            imgListAndVideoList.push(...this.uploadVideoList, ...stays, ...this.uploadImgList)

            this.$emit("getFileList", imgListAndVideoList)
        },
        moveItem(index, type) {
            let imgListAndVideoList = this.imgListAndVideoList
            if (type == 0) {
                imgListAndVideoList.splice(index - 1, 0, ...imgListAndVideoList.splice(index, 1));
            } else if (type == 1) {
                imgListAndVideoList.splice(index + 1, 0, ...imgListAndVideoList.splice(index, 1));
            }
            this.$emit("getFileList", imgListAndVideoList)
        },
        onDelete(index) {
            let imgListAndVideoList = this.imgListAndVideoList
            const delItem = imgListAndVideoList[index]
            if (delItem.type == "img") {
                const imgIndex = this.uploadImgList.findIndex(f => f.serverFileName == delItem.serverFileName)
                if (imgIndex != -1) {
                    this.uploadImgList.splice(imgIndex, 1)
                }
            } else if (delItem.type == "video") {
                const videoIndex = this.uploadVideoList.findIndex(f => f.serverFileName == delItem.serverFileName)
                if (videoIndex != -1) {
                    this.uploadVideoList.splice(videoIndex, 1)
                }
            }
            imgListAndVideoList.splice(index, 1)
            this.$emit("getFileList", imgListAndVideoList)
        }
    }
}
</script>
<style scoped>
.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fafafa;
    margin: 0 5px 5px 0;
}

.card:hover {
    color: #fff;
    background: #027BE3;
}

.media {
    position: relative;
}

.media-item {
    position: relative;
}

.media-item-cover {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 12px;
    color: red;
    background: #fff;
    user-select: none;
}

.media-delete {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 5px;
    font-size: 13px;
    font-weight: bold;
    color: #000;
    background: #fff;
    cursor: pointer;
}

.media-item-value {
    width: 120px;
    height: 120px;
    margin: 5px;
    background: #fff;
}

.move-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.move-btn-value {
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}
</style>