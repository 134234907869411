<template>
  <div class="video">
      <video :id="id" class="video-value" :src="video.url" frameborder="0" :autoplay="0"></video>
      <div class="play-video" @click="playVideo">播放/暂停</div>
  </div>
</template>
<script>
export default {
  props: {
      id: {
          type: String,
          default: ''
      },
      video: {
          type: Object,
          default() {
              return {}
          }
      }
  },
  methods: {
      playVideo() {
          const video = document.getElementById(this.id);
          if (video.paused) {
              video.play();
          } else {
              video.pause();
          }
      }
  }
}
</script>
<style scoped>
.video {
  position: relative;
}

.video-value {
  width: 100%;
  height: 100%;
}

.play-video {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 5px;
  font-size: 12px;
  color: #fff;
  background: #000;
  cursor: pointer;
}

.play-video:active {
  opacity: 0.8;
}
</style>