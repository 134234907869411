<template>
  <div class="_component">
      <div class="upload">
          <label>
              <div class="upload-btn">添加图片</div>
              <input style="display: none;" type="file" accept="image/*" multiple @change="handleImgFileSelect">
          </label>
          <span class="loader" v-show="imgUploading"></span>
          <div class="upload-img-list" v-if="showUploadImg">
              <div class="upload-img-list-wrap" v-for="(item, index) in uploadImgList" :key="index">
                  <div class="upload-img-list-wrap-img">
                      <img class="upload-img-list-wrap-img-value" :src="item.url">
                      <div class="upload-img-list-wrap-img-delete" @click="onDeleteImg(index)">x</div>
                  </div>
              </div>
          </div>
      </div>

      <div class="upload">
          <label>
              <div class="upload-btn">添加视频</div>
              <input style="display: none;" type="file" accept="video/*" multiple @input="handleVideoFileSelect">
          </label>
          <span class="loader" v-show="videoUploading"></span>
          <div class="upload-video-list" v-if="showUploadVideo">
              <div class="upload-video-list-wrap" v-for="(item, index) in uploadVideoList" :key="index">
                  <div class="upload-video-list-wrap-video">
                      <VideoComponent :id="`video${index}`" :video="item" />
                      <div class="upload-video-list-wrap-video-delete" @click="onDeleteVideo(index)">x</div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
import VideoComponent from "./VideoComponent.vue"
import axios from "axios";
export default {
  props: {
      showUploadImg: {
          type: Boolean,
          default: true
      },
      showUploadVideo: {
          type: Boolean,
          default: true
      },
      uploadImgMax: {
          type: Number,
          default: 9
      },
      uploadVideoMax: {
          type: Number,
          default: 1
      },
      uploadImgList: {
          type: Array,
          default() {
              return []
          }
      },
      uploadVideoList: {
          type: Array,
          default() {
              return []
          }
      },
      uploadImgServerUrl: {
          type: String,
          default: ""
      },
      uploadVideoServerUrl: {
          type: String,
          default: ""
      }
  },
  components: {
      VideoComponent
  },
  data() {
      return {
          imgUploading: false,
          videoUploading: false
      }
  },
  methods: {
      handleImgFileSelect(e) {
          this.imgUploading = true
          const files = e.target.files
          const seleteCount = files.length
          const maxLoadCount = this.uploadImgMax
          const loadCount = seleteCount + this.uploadImgList.length
          if (loadCount > maxLoadCount || seleteCount > maxLoadCount) {
              alert(`添加数量不能超过${maxLoadCount}个`)
              e.target.value = ''; // 清空文件选择框的值，以便重新选择文件
              this.cleanFailImgFile(files)
              this.imgUploading = false
              return
          }
          let uploadImgList = this.uploadImgList;
          let _files = []
          for (let i = 0; i < files.length; i++) {
              const file = files[i]
              _files.push(file)
              uploadImgList.push({
                  file,
                  name: file.name,
                  serverFileName: "",
                  url: "",
                  type: "img"
              })
          }

          const formData = new FormData();
          uploadImgList.forEach(item => {
              formData.append('file', item.file);
          });
          axios.post(this.uploadImgServerUrl, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          }).then(res => {
              if (res.data.code != 100) {
                  alert(res.data.msg)
                  this.cleanFailImgFile(_files)
                  this.imgUploading = false
                  return
              }
              if (res.data.code == 100) {
                  res.data.data.forEach((item) => {
                      let findIndex = uploadImgList.findIndex(f => f.name == item.rowFileName)
                      if (findIndex != -1) {
                          uploadImgList[findIndex].serverFileName = item.serverFileName
                      }
                  })
                  this.$emit("getUploadImgList", uploadImgList)
                  this.imgUploading = false
              }
          }).catch(error => {
              alert('文件上传失败')
              console.error('文件上传失败', error);
              this.cleanFailImgFile(_files)
              this.imgUploading = false
          });
          e.target.value = ''; // 清空文件选择框的值，以便重新选择文件
      },
      onDeleteImg(index) {
          let uploadImgList = this.uploadImgList
          uploadImgList.splice(index, 1)
          this.$emit("getUploadImgList", uploadImgList)
      },
      cleanFailImgFile(files) {
          let _this = this
          for (let i = 0; i < files.length; i++) {
              let index = _this.uploadImgList.findIndex(f => f.name == files[i].name)
              if (index != -1) {
                  _this.uploadImgList.splice(index, 1)
              }
          }
      },
      handleVideoFileSelect(e) {
          this.videoUploading = true
          const files = e.target.files
          const seleteCount = files.length
          const maxLoadCount = this.uploadVideoMax
          const loadCount = seleteCount + this.uploadVideoList.length
          if (loadCount > maxLoadCount || seleteCount > maxLoadCount) {
              alert(`添加数量不能超过${maxLoadCount}个`)
              this.cleanFailVideoFile(files)
              e.target.value = ''; // 清空文件选择框的值，以便重新选择文件
              this.videoUploading = false
              return
          }
          let uploadVideoList = this.uploadVideoList
          let _files = []
          for (let i = 0; i < files.length; i++) {
              const file = files[i]
              _files.push(file)
              uploadVideoList.push({
                  file,
                  name: file.name,
                  serverFileName: "",
                  url: "",
                  type: "video"
              })
          }

          const formData = new FormData();
          uploadVideoList.forEach(item => {
              formData.append('file', item.file);
          });
          axios.post(this.uploadVideoServerUrl, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          }).then(res => {
              if (res.data.code != 100) {
                  alert(res.data.msg)
                  this.cleanFailVideoFile(_files)
                  this.videoUploading = false
                  return
              }
              if (res.data.code == 100) {
                  res.data.data.forEach((item) => {
                      let findIndex = uploadVideoList.findIndex(f => f.name == item.rowFileName)
                      if (findIndex != -1) {
                          uploadVideoList[findIndex].serverFileName = item.serverFileName
                      }
                  })
                  this.$emit("getUploadVideoList", uploadVideoList)
                  this.videoUploading = false
              }
          }).catch(error => {
              alert('文件上传失败')
              console.error('文件上传失败', error);
              this.cleanFailVideoFile(_files)
              this.videoUploading = false
          });
          e.target.value = ''; // 清空文件选择框的值，以便重新选择文件
      },
      onDeleteVideo(index) {
          let uploadVideoList = this.uploadVideoList
          uploadVideoList.splice(index, 1)
          this.$emit("getUploadVideoList", uploadVideoList)
      },
      cleanFailVideoFile(files) {
          let _this = this
          for (let i = 0; i < files.length; i++) {
              let index = _this.uploadVideoList.findIndex(f => f.name == files[i].name)
              if (index != -1) {
                  _this.uploadVideoList.splice(index, 1)
              }
          }
      }
  }
}
</script>
<style scoped>
._component {
  display: flex;
  flex-direction: column;
}

.upload {
  display: flex;
  align-items: center;
}

.upload-img-list,
.upload-video-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.upload-img-list-wrap,
.upload-video-list-wrap {
  margin: 0 5px 5px 0;
}

.upload-img-list-wrap-img {
  position: relative;
  width: 100px;
  height: 100px;
}

.upload-img-list-wrap-img-value {
  width: 100%;
  height: 100%;
}

.upload-img-list-wrap-img-delete,
.upload-video-list-wrap-video-delete {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 0 5px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background: #000;
}


.upload-video-list-wrap-video {
  position: relative;
  width: 200px;
  height: 150px;
}



.upload-btn {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 5px;
  color: #fff;
  background: #027BE3;
}

.upload-btn:active {
  opacity: 0.8;
}

.loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #f3f3f3;
  border-top-color: #3498db;
  animation: spin 1.5s infinite linear;
}

@keyframes spin {
  from {
      transform: rotate(0deg);
  }

  to {
      transform: rotate(360deg);
  }
}
</style>